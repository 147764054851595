/* Container for the form */
.payment-form-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Form styling */
.payment-form {
  display: flex;
  flex-direction: column;
}

/* Labels styling */
.payment-form label {
  margin-bottom: 10px;
  font-weight: 600;
}

/* Stripe Elements styling */
.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
}

/* Button styling */
.payment-form button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #5469d4;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.payment-form button:disabled {
  background-color: #d3d3d3;
  cursor: not-allowed;
}

.payment-form button:hover:not(:disabled) {
  background-color: #4353b3;
}