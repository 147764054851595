
/*Media Query Mixing*/
@mixin respond-to($breakpoint) {
    @if $breakpoint == "media-xs" {
      @media (max-width: 575px) {
        @content;
      }
    }
    @if $breakpoint == "media-sm" {
        @media (max-width: 768px) {
          @content;
        }
      }
    
    @else if $breakpoint == "media-md" {
      @media (max-width: 991px) {
        @content;
      }
    }
  
    @else if $breakpoint == "media-lg" {
      @media (max-width: 1199px) {
        @content;
      }
    }
    @else if $breakpoint == "media-xl" {
        @media (max-width:1499px) {
            @content;
        }
    }
  }
  $primary:#29D1B8;
  $darkGray:#333;
  $whtClr:#fff;
  $primary-font:  "Roboto", sans-serif;
  $borderRad: 12px;